<template>
  <div class="pa-5">
  
    <h2 :style="{color:franchiseData.brand_text_color}">Thank You!</h2>
    <div class="space-30"></div>
    <p>Your quote must be reviewed by an NFIB staff member before you are able to purchase this policy.</p>
    <div class="space-10"></div>
    <p>This process generally takes about 24 hours, and a staff member will contact you with further details on completion.
    </p>
    <div class="space-10"></div>
    <p>Please note, no cover is in place until you have paid for your required policies and the commencement date has been reached.</p>
    <div class="space-20"></div>
    <p><b>The NFIB Team</b></p>
  </div>
</template>

<script>
import FranchiseMixin from '@/mixins/franchise-style';
  export default {
    name:"CoverageThankYou",
    mixins: [FranchiseMixin],
  }
</script>

<style lang="scss" scoped>

</style>